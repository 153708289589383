.leaflet-container {
	height: 400px;
	width: 100%;
	margin: 0 auto;
}

.leaflet-marker-live {
	-webkit-animation: pulse 2s ease-out;
	animation: pulse 1s ease-out;
	-webkit-animation-iteration-count: infinite;
	animation-iteration-count: infinite;
}
@-webkit-keyframes pulse {
	from { stroke-width: 15; stroke-opacity: 1; }
	to { stroke-width: 50; stroke-opacity: 0; }
}
@keyframes pulse {
	from { stroke-width: 15; stroke-opacity: 1; }
	to { stroke-width: 50; stroke-opacity: 0; }
}
