.App {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-flex-direction: column;
          flex-direction: column;
  text-align: center;
  margin: 0 auto;
  font-family: Roboto, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}

.App h2 {
  font-size: 1.25em;
  line-height: 1em;
  margin-bottom: 1em !important;
}

.App .tab-content-container {
  margin-top: 0.5em;
}

.App .stage-info,
.App .result-container {
  text-align: center;
}

.posts {
  list-style-type: none;
}
.live {
  border: 2px solid #bb1919;
  border-radius: 50%;
  display: inline-block;
  height: 1em;
  position: relative;
  top: -1px;
  vertical-align: middle;
  width: 1em;
}

.live:after {
  -webkit-animation: pulse 1.7s ease infinite;
          animation: pulse 1.7s ease infinite;
  background-color: #bb1919;
  border-radius: 50%;
  bottom: 1px;
  content: ' ';
  display: block;
  left: 1px;
  position: absolute;
  right: 1px;
  top: 1px
}

@-webkit-keyframes pulse {
  0% {
    opacity: 0.0;
    content: 'fdfdsf';
  }
  50% {
    opacity: 1.0;
    content: 'fdfdsf';
  }
  100% {
    opacity: 0.0;
    content: 'fdfdsf';
  }
}

#chat-wrapper {
  z-index: 100000000 !important;
  overflow: hidden !important;
  opacity: 1 !important;
  right: 20px;
  bottom: 100px;
  position: fixed !important;
  box-shadow: 0 5px 40px rgba(0,0,0,.16);
  height: 500px !important;
  max-height: 500px !important;
}
#chat-wrapper iframe {
  width: 100% !important;
  height: 100% !important;
}

#chat-launcher-container {
  z-index: 100000000 !important;
  overflow: hidden !important;
  opacity: 1 !important;
  right: 20px;
  bottom: 20px;
  position: fixed !important;
  box-shadow: 0 5px 40px rgba(0,0,0,.16);
  max-height: 500px !important;
	line-height: 1;
  width: 60px !important;
  height: 60px !important;
  border-radius: 50% !important;
  box-shadow: 0 1px 6px rgba(0,0,0,.06),0 2px 32px rgba(0,0,0,.16) !important;
}

#chat-launcher-container #chat-launcher {
  cursor: pointer;
  width: 60px;
  height: 60px;
  background: #1fbceb;
  overflow: hidden;
  border-radius: 50%;
  cursor: pointer;
}

#chat-launcher-container #chat-launcher #chat-launcher-open-icon {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAkCAYAAADo6zjiAAAAAXNSR0IArs4c6QAAAr5JREFUWAntVz2MEkEUluXnTuGQwxAwV2ihMRcLicTSaywwdhZezsbKmtrKikQajN0lhsLC2JlcYujBGEJBIZ5iAwnEkJCYEORfWBa/b8Pc7S23d7kTttqXPObtvDfv++bN7DJju3Aotpkp2kPPYq3pLJ1o1UeCSv1+f1tRlO/T6VSGLlpk5N4HxhNiQQ8mqoJ3Op3tRSMa5QPWERIk4JxMJvtGAxbdD6xvxGQV1NnzASBdtA6oGSLbbDYPgMZcCwpbs8CJRywVWxA42BD0miQqpiBgEuY8jEXAqoBVAasCVgWsChhWQJblOs4I8vzfx9l7cABpGI06lsB4PC47nc576XT64Wg0+mE0+LR+5KkVCoWnDofjLuyKUTxJXNIeu8D4TyaTuY9+XygUCpRKpec4TH5mRbRxBrYyHA4L5XI5FolEriLHejab3WJObTwxoWoB5gjMAv92u93k7u7uBgIvQ6/EYrGbxWLxWb1ef9VsNt+32+096CfYHxqNxmsSTSQStxkL9aVSqWvI8Qb5Rlpw2vCfSkAdg6N0ezAYvK1UKg/C4bAPA0mGrV+n6/RFo1F/tVp9hDHvMLanBxbPiFUJiEPpKhw9dJ4oiGmhlF+xQX9Cf8PuYIAdsoZ1DkE3Yd/BgdN9YiI4ZzFDQWAFyX5JksTSmSKCgHgLpr1eb88UZB0ICXBDKPF4/CV27xedf+mPXAKxDC7Yq/l8fisYDN5Cic5zT7B7vd5Nt9sdcblcN5BPVHhuImIJBAEGMJjXJXFpoO+sIiYjJZPJjR1IIBDYwUftuj6RICD6BRGSIAESOY+yiivQi1BevXwejyeQy+Uet1qtj3gtB/rXEDFHRBD5n5aTEBMhIZJZg/r5IavVai/weefllP0SgZYh2ryCkCBlB6ACHUFlbeAyiDCnwGBLJREKSSjCqfYs+edYrH/16LJPC4LGtQAAAABJRU5ErkJggg==);
  background-size: 32px 36px;
  background-repeat: no-repeat;
  background-position: center 14px;
  opacity: 1;
  width: 100%;
  height: 100%;
}

#chat-launcher-container #chat-launcher #chat-launcher-close-icon {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAOBAMAAADtZjDiAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAG1BMVEUAAAD///////////////////////////8AAADr8xjQAAAAB3RSTlMAM7cPx7jIAE21/gAAAAFiS0dEAIgFHUgAAAAJcEhZcwAACxIAAAsSAdLdfvwAAABESURBVAjXYxAyYGBgYFZkUHcG0ialDCYlBgzM7slA7MxgUgaUNCkzdgfJMbunlIDUMpiUg7hwGiYOVQfTBzMHZi7UHgCB3RAZ7HszogAAAABJRU5ErkJggg==);
  background-size: 14px 14px;
  background-repeat: no-repeat;
  background-position: 50%;
  opacity: 1;
  width: 100%;
  height: 100%;
}

.hide {
  display: none;
}

.user-wrapper,
.race-wrapper {
  width: 100%;
}

.contents ul {
  list-style: none;
  padding: 0;
}

.contents ul li {
  padding: 1em 0;
  border-top-color: #bdbdbd;
  border-top-width: 1px;
  border-top-style: solid;
  color: #252525;
}

.contents ul li img {
  width: 80%;
}

.contents ul li:nth-of-type(odd) {
  /*
  background: lightsteelblue;
  */
}

.contents ul li p.publish-date {
  text-align: left;
}

.left {
  float: left;
}

.right {
  float: right;
}

.half-width {
  width: 50%;
}

.clear {
  clear: both;
}

.posts.with-situation {
  float: left;
  width: 70%;
}

.situation-container {
  float: right;
  width: 25%;
}

.start-list {
  margin: 1em 0;
}

#back-button {
	width: 2em;
	position: absolute;
	top: 2em;
	left: 2em;
  width: 32px;
  height: 32px;
  cursor: pointer;
}

.leaflet-container {
	height: 400px;
	width: 100%;
	margin: 0 auto;
}

.leaflet-marker-live {
	-webkit-animation: pulse 2s ease-out;
	animation: pulse 1s ease-out;
	-webkit-animation-iteration-count: infinite;
	animation-iteration-count: infinite;
}
@-webkit-keyframes pulse {
	from { stroke-width: 15; stroke-opacity: 1; }
	to { stroke-width: 50; stroke-opacity: 0; }
}
@keyframes pulse {
	from { stroke-width: 15; stroke-opacity: 1; }
	to { stroke-width: 50; stroke-opacity: 0; }
}

body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

